@font-face {
  font-family: "Gilroy bold";
  src: url("./assets/fonts/Gilroy-Bold.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Gilroy light";
  src: url("./assets/fonts/Gilroy-Light.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Gilroy Semibold";
  src: url("./assets/fonts/Gilroy-SemiBold.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Gilroy Extrabold";
  src: url("./assets/fonts/Gilroy-ExtraBold.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Gilroy medium";
  src: url("./assets/fonts/Gilroy-Medium.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Gilroy roman";
  src: url("./assets/fonts/Gilroy-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Gilroy italic";
  src: url("./assets/fonts/Gilroy-MediumItalic.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

* {
  box-sizing: border-box;
  scrollbar-width: thin;
  scrollbar-color: #626366;
}

*::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}
*::-webkit-scrollbar-track {
  background: #62636640;
}
*::-webkit-scrollbar-thumb {
  background-color: #62636660;
  border-radius: 20px;
}

html,
body,
#root {
  height: 100%;
  width: 100%;
  font-size: 16px;
}

body {
  margin: 0;
  font-family: "neue roman", "Segoe UI", "Roboto", "Oxygen", "Ubuntu",
    "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
